<script setup lang="ts">
import type {RouteLocationRaw} from 'vue-router'

const props = withDefaults(
    defineProps<{
      /** The location to which the button should navigate when clicked. This is only applicable if the button is a link. */
      to?: RouteLocationRaw

      /** Using href instead of to result in a native anchor with no router functionality. */
      href?: string

      /** The value of the 'rel' attribute of the button. This attribute is used to specify the relationship of the linked document with the current document. */
      rel?: string

      /** The value of the 'target' attribute of the button. This attribute is used to specify where to open the linked document. */
      target?: string

      /** The type of the button. */
      type?: 'button' | 'submit' | 'reset'

      /** The button badge indicator */
      badge?: boolean

      /** Add a pulse animation on the badge */
      badgePulse?: boolean

      /** Whether the button is in a loading state. */
      loading?: boolean

      /** Whether the button should be disabled. */
      disabled?: boolean

      color?: 'primary' | 'default',

      /**
       * The radius of the button.
       *
       * @since 2.0.0
       * @default 'sm'
       */
      rounded?: 'none' | 'sm' | 'md' | 'lg' | 'full'

      /**
       * The size of the button
       *
       * @default 'md'
       */
      size?: 'sm' | 'md' | 'lg' | 'xl'

      /**
       * The variant of the button..
       *
       * @since 2.0.0
       * @default 'solid'
       */
      variant?: 'solid' | 'outline' | 'pastel',

      fullWidth?: boolean
    }>(),
    {
      to: undefined,
      href: undefined,
      rel: '',
      target: '',
      size: undefined,
      variant: undefined,
      color: undefined,
      rounded: undefined,
      fullWidth: false,
      loading: false,
      type: 'button'
    },
);
const classes = computed(() => ({
  'button-full-width': props.fullWidth,
  'button-disabled': attributes.value.disabled,

  //colors
  'button-primary': props.color === 'primary',

  //sizes
  'button-size-small': props.size === 'sm',
}));

const disabled = computed(() => {
  return props.disabled || props.loading;
})

const {attributes, is} = useButton(props);

const loadingClass = computed(() => {
  return props.loading ? 'button-loader--visible' : '';
});
</script>

<template>
  <component :is="is" v-bind="attributes" class="button" :class="classes" :type="type" :disabled="disabled">
    <slot name="icon-before"/>
    <slot />
    <div class="button-loader" :class="loadingClass">
      <UISpinner size="small"/>
    </div>
  </component>
</template>

<style>
.button {
  --_color: var(--text-color);
  --_bgc-color: var(--background);
  --_button-size: var(--tw-font-size-sm);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--_color);
  border: 1px solid var(--border-color);
  outline: 1px solid transparent;
  border-radius: var(--tw-border-radius-md);
  padding: 0.5em 1.25em;
  gap: 0.25rem;
  transition: 0.3s background-color, color 0.3s, border-color 0.3s, outline-color 0.3s;
  font-size: var(--_button-size);
  font-weight: var(--tw-font-weight-light);
  line-height: var(--tw-line-height-7);
  background: var(--_bgc-color);

  &:focus-visible {
    outline-color: var(--Fon__Process_Fon);
    border-color: var(--Border__Process);
  }
}

.button-primary {
  --_bgc-color: rgb(var(--tw-color-sky-400));
  --_color: white;
  border: none;
}

.button.button-primary:hover, .button.button-primary:focus {
  --_bgc-color: rgb(var(--tw-color-sky-400) / 0.8);
}

.button:hover, .button:focus {
  --_bgc-color: var(--background-hover);
}

.button-full-width {
  width: 100%;
}

.button-disabled {
  pointer-events: none;
}

.button-size-small {
  --_button-size: var(--tw-font-size-xs);
}

.button-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(14px);
  border-radius: inherit;
  visibility: hidden;
}

.button-loader--visible {
  visibility: visible;
}
</style>
